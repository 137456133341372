<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <a-config-provider :locale="locale">
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view class="router-view" v-slot="{ Component }">
        <transition :name="transitionName">
          <component :is="Component" />
        </transition>
      </router-view>
    </keep-alive>
    <router-view class="router-view" v-slot="{ Component }" v-else>
        <transition :name="transitionName">
          <component :is="Component" />
        </transition>
    </router-view>
    <app-socket v-if="$route.path=='/student'||$route.path=='/studentInfo'
    ||$route.path=='/capabilityAnalysis'||$route.path=='/meCapability'
    ||$route.path=='/curriculumStructure'||$route.path=='/exhibit'||$route.path=='/planning'
    ||$route.path=='/end'"/>
  </a-config-provider>
</template>
<script>

import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
  name: 'App',
  data() {
    return {
      locale: zhCN,
      transitionName: 'slide-left',
    };
  },
  watch: { // 使用watch 监听$router的变化
    $route(to, from) {
      if (to.meta.index > from.meta.index) {
        this.transitionName = 'slide-left';
        console.log('slide-left');
      } else if (to.meta.index < from.meta.index) {
        this.transitionName = 'slide-right';
        console.log('slide-right');
      } else {
        this.transitionName = '';
      }
    },
  },
};
</script>
<style lang="less">
.back{
  position: absolute;
  top: 0.16rem;
  left: 0.31rem;
  z-index: 1000;
  img{
    width: 0.52rem;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  width: 100vw;
}
*{
  // -moz-user-select:none; /*火狐*/
  //   -webkit-user-select:none; /*webkit浏览器*/
  //   -ms-user-select:none; /*IE10*/
  //   -khtml-user-select:none; /*早期浏览器*/
  //   user-select:none;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.router-view{
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    -webkit-overflow-scrolling: touch;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active{
    height: 100%;
    will-change: transform;
    transition: all 500ms cubic-bezier(.55,0,.1,1);
    position: absolute;
    backface-visibility: hidden;
}
.slide-right-enter-active{
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active{
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}
.slide-left-enter-active{
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active{
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}
 .to{
        position: absolute;
        display: flex;
        align-items: center;
        right: 0.32rem;
        bottom: 0.25rem;
        .to-left{
            font-size: 0.14rem;
            font-weight: 400;
            color: #333029;
            line-height: 0.17rem;
            .to-t2{
                font-size: 0.12rem;
                opacity: 0.8;
                transform: scale(0.75);
                transform-origin: 0 0;
            }
        }
        img{
            width: 1.04rem;
            height: 0.1rem;
            margin-left: 0.16rem;
        }
    }
</style>

import Cookies from 'js-cookie';

const TokenKey = 'Admin-Token';
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setUserName(data) {
  return Cookies.set('userName', data);
}
export function getUserName() {
  return Cookies.get('userName');
}

import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录',
      index: 1,
      keepAlive: false,
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '主页',
      index: 2,
      keepAlive: false,
    },
  },
  {
    path: '/student',
    name: 'Student',
    component: () => import('../views/Student.vue'),
    meta: {
      title: '',
      index: 3,
      keepAlive: false,
    },
  },
  {
    path: '/studentInfo',
    name: 'StudentInfo',
    component: () => import('../views/StudentInfo.vue'),
    meta: {
      title: '学生信息',
      index: 4,
      keepAlive: false,
    },
  },
  {
    path: '/capabilityAnalysis',
    name: 'CapabilityAnalysis',
    component: () => import('../views/CapabilityAnalysis.vue'),
    meta: {
      title: '雷达图',
      index: 5,
      keepAlive: true,
    },
  },
  {
    path: '/meCapability',
    name: 'MeCapability',
    component: () => import('../views/MeCapability.vue'),
    meta: {
      title: '雷达图',
      index: 6,
      keepAlive: false,
    },
  },
  {
    path: '/curriculumStructure',
    name: 'CurriculumStructure',
    component: () => import('../views/CurriculumStructure.vue'),
    meta: {
      title: '课程体系',
      index: 7,
      keepAlive: false,
    },
  },
  {
    path: '/reward',
    name: 'Reward',
    component: () => import('../views/Reward.vue'),
    meta: {
      title: '收获',
      index: 8,
      keepAlive: false,
    },
  },
  {
    path: '/exhibit',
    name: 'Exhibit',
    component: () => import('../views/Exhibit.vue'),
    meta: {
      title: '展示',
      index: 9,
      keepAlive: false,
    },
  },
  {
    path: '/planning',
    name: 'Planning',
    component: () => import('../views/Planning.vue'),
    meta: {
      title: '规划',
      index: 10,
      keepAlive: false,
    },
  },
  {
    path: '/end',
    name: 'End',
    component: () => import('../views/End.vue'),
    meta: {
      title: '规划',
      index: 11,
      keepAlive: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

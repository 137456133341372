import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import Vue3TouchEvents from 'vue3-touch-events';
import App from './App.vue';
import router from './router';
import store from './store';
import 'ant-design-vue/dist/antd.css';
import { server } from './utils/http-service';
// eslint-disable-next-line import/extensions
import 'lib-flexible/flexible.js';
import socketApi from './utils/socket';
import AppSocket from './components/AppSocket.vue';

const app = createApp(App);
app.component('app-socket', AppSocket);
app.use(store)
  .use(router)
  .use(Antd)
  .use(Vue3TouchEvents)
  .use(AppSocket)
  .mount('#app');

app.config.globalProperties.$server = server;
app.config.globalProperties.$socketApi = socketApi;

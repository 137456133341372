<template>
  <div class="login">
    <div class="main">
        <div class="title">嘉悦芭蕾 学情报告系统</div>
        <div class="row">
            <img src="../assets/img/login/icon-user.png" alt="">
            <a-input v-model:value="name" placeholder="用户名" />
        </div>
        <div class="row">
            <img src="../assets/img/login/icon-pwd.png" alt="">
            <a-input-password v-model:value="pwd"  placeholder="密码" />
        </div>
        <a-button type="primary" @click="login">登录</a-button>
    </div>
  </div>
</template>

<script>
import { setToken, setUserName } from '../utils/auth';

export default {
  data() {
    return {
      title: '',
      name: '',
      pwd: '',
      issend: false,
    };
  },
  created() {

  },
  mounted() {

  },
  methods: {
    login() {
      const that = this;
      if (this.issend) return;
      this.issend = true;
      this.$server.post('/api/study/login', {
        phone: this.name,
        password: this.pwd,
      }).then((res) => {
        setTimeout(() => {
          that.issend = false;
        }, 1000);

        if (res.code === 200) {
          setToken(res.data.token);
          localStorage.setItem('token', res.data.token);
          setUserName(res.data.teacher.name);
          that.$message.success('登录成功');
          //   that.backPage(res.data);
          this.$router.push({
            name: 'Home',
          });
        //   that.$toast('转班成功');
        } else {
        //   that.$toast(res.message);
        }
      });
    },
  },
};
</script>
<style lang="less">
.title{
    color: #000000;
    text-align: center;
    font-size: 0.25rem;
    // font-weight: bold;
}
.title4{
    color: #000;
    font-size: 0.16rem;
}
.title2{
    font-size: 0.23rem;
    color: #333029;
  }
.ant-input,.ant-input-password,.ant-select-selector{
    color: #66635C;
    border: none !important;
    box-shadow: none !important;
    background: none;
}
.ant-input-suffix{
    display: none;
}
.login{
    width: 100vw;
    height: 100vh;
    font-size: 0.32rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFDFA no-repeat;
    background-image: url('../assets/img/login/bg.png') ;
    background-size: 100% auto;
    .main{
        width: 2.97rem;
        padding-bottom: 1.2rem;
        .title{
            line-height: .36rem;
            margin-bottom: .63rem;
        }
        .row{
            background: #FFFDFA;
            display: flex;
            align-items: center;
            height: .47rem;
            font-size: .12rem;
            border-bottom: 1px solid #F0E9D8;
            padding-left: .16rem;
            img{
                height: .16rem;
            }
        }
        .ant-btn{
            margin-top: 0.39rem;
            width: 100%;
            height: .43rem;
            background: #EDCA7E;
            color: #fff;
            font-size: .14rem;
            border-color: #EDCA7E;
            border-radius: .04rem;
        }
    }

}
</style>

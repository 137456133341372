<template>
    <div class="socket">
    </div>
  </template>

<script>
export default {
  name: 'AppSocket',
  created() {
    const token = localStorage.getItem('token');
    const student = JSON.parse(localStorage.getItem('student')).student_id;
    // eslint-disable-next-line no-debugger
    // debugger;
    // ws://api.joyballet.cn:9504
    this.$socketApi.initWebSocket(`ws://101.200.63.202:9504?token=${token}&student_id=${student}`, (e) => {
      if (e !== 'pong') {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    });
  },
  beforeUnmount() {
    this.$socketApi.webSocketClose();
  },
};
</script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="less">
  @font-face {
      font-family: "ZhanKu";
      src: url('../assets/font/ZhanKuKuaiLeTi2016XiuDingBan-2.ttf');
  }
  h1{
    font-family: "ZhanKu";
  }
  h3 {
    margin: 40px 0 0;
    font-family: "ZhanKu";
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>
